
import React from 'react'
import {usePrismicDocumentsByTag} from '@prismicio/react';

export default function News()  {
    const [news] = usePrismicDocumentsByTag("news");
    console.log(news)
    let articleRedirect  = param => e => {
        window.location = '/article?id=' + param;
    }


    return (
        <main>
            {news && (
                <section id="article" className="article news">
                    <div className="container mt-5">

                        <div className="section-title">
                            <h2>{news.results[0].data.heading[0].text}</h2>
                        </div>
                        <div className="row portfolio-container">
                            {news.results[0].data.news_list.map((item, index) => (
                                <div className="row mb-5 pb-5" key={index}>
                                    <div onClick={articleRedirect(index)} className="col-lg-4">
                                        <img src={item.image.url} className="img-fluid link-article" alt="Parents holding a little child"/>
                                    </div>
                                    <div className="col-lg-8 pt-4 pt-lg-0">
                                        <h3 className="pb-3 link-article" onClick={articleRedirect(index)}> {item.heading[0].text} </h3>
                                        <p dangerouslySetInnerHTML={{__html: item.paragraph[0].text}}></p>

                                    </div>
                                    <p className="post-date pt-2"><span className="bi-pen">&nbsp;</span>{item.date[0].text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
        </main>
    )
}