import React from 'react'
import {usePrismicDocumentsByTag} from '@prismicio/react';
import HeroBanner from "../components/HeroBanner";

export default function Links() {
    const [page] = usePrismicDocumentsByTag("links");

    return (
        <main>
            {page && (
                <section className="section-bg">
                    <HeroBanner
                        id="volunteers-hero"
                        heading={page.results[0].data.hero_title[0].text}
                    />
                    <div className="container pt-5">
                        <div className="row">
                            {/*<div className="col-lg-6">*/}
                            {/*    <img src={page.results[0].data.hero_image.url} className="img-fluid" alt="Parents holding a little child"/>*/}
                            {/*</div>*/}

                            <div className="col-lg-8 pt-4 pt-lg-0">
                                <h4>{page.results[0].data.subheading1[0].text}</h4>

                                <ul className="list-group pb-4">
                                    {page.results[0].data.list1.map((item, index) => (
                                        <li><a href={item.path[0].text} target="_blank"><i className="bi-link icon-color"></i> {item.name[0].text}</a>
                                        </li>
                                    ))}
                                </ul>
                                <h4>{page.results[0].data.subheading2[0].text}</h4>
                                <ul className="list-group pb-4">
                                    {page.results[0].data.list2.map((item, index) => (
                                        <li><a href={item.path[0].text} target="_blank"><i className="bi-link icon-color"></i> {item.name[0].text}</a>
                                        </li>
                                    ))}
                                </ul>
                                <h4>{page.results[0].data.subheading3[0].text}</h4>
                                <ul className="list-group pb-4">
                                    {page.results[0].data.list3.map((item, index) => (
                                        <li><a href={item.path[0].text} target="_blank"><i className="bi-link icon-color"></i> {item.name[0].text}</a>
                                        </li>
                                    ))}
                                </ul>
                                <h4>{page.results[0].data.subheading4[0].text}</h4>
                                <ul className="list-group pb-4">
                                    {page.results[0].data.list4.map((item, index) => (
                                        <li><a href={item.path[0].text} target="_blank"><i className="bi-link icon-color"></i> {item.name[0].text}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

            )}
        </main>
    )
}