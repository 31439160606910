import React from 'react';

const HeroBanner = (props) => {
    return <section id={props.id} className="hero-banner d-flex flex-column justify-content-center align-items-center">
        <div className="container text-center text-md-left" data-aos="fade-up">
            <h1>{props.heading}</h1>
            <h2>{props.subheading} </h2>
            {props.button &&
                <a href={props.url} className="btn-get-started scrollto">{props.button}</a>
            }
        </div>
    </section>
};
export default HeroBanner;


