

import HeroBanner from "../../components/HeroBanner";
import Steps from "../../components/Steps";
import {usePrismicDocumentsByTag} from '@prismicio/react';

export default function HaitiProgram()  {

    const [country] = usePrismicDocumentsByTag("haiti");

    return (
        <main>
            {country && (
                <div className="program-container">
                    <HeroBanner
                        id="haiti-program"
                        heading={country.results[0].data.hero_title[0].text}>
                    </HeroBanner>
                    <Steps
                        image={country.results[0].data['list-image'].url}
                        paragraph={country.results[0].data.paragraph[0].text}
                        heading={country.results[0].data.heading[0].text}
                        steps={country.results[0].data.step_list}
                    />

                    <section>
                        <div className="container">
                            <div className="section-title">
                                <h2>{country.results[0].data.families_number[0].text}</h2>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </main>
    )

}