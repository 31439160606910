import React from 'react';
export default function(props) {
    return <section id="what-we-do" className="what-we-do">
        <div className="container">

            <div className="section-title">
                <h2>{props.propsHeading}</h2>
                <p>{props.propsParagraph}</p>
            </div>

            <div className="row">
                {props.props.map((prop, index) => (
                <div key={index} className="col-lg-4 col-md-6 d-flex align-items-stretch pb-3">
                    <div className="icon-box">
                        <div className="icon"><i  className={prop.prop_icon[0].text}></i></div>
                        <h4 dangerouslySetInnerHTML={{__html: prop['prop_text'][0].text}}></h4>
                    </div>
                </div>
                ))}
            </div>

        </div>
    </section>
}