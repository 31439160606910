import Services from "../components/Services";
import AboutSection from "../components/AboutSection";
import Statistics from "../components/Statistics";
import WhoWeAre from "../components/WhoWeAre";
import HeroBanner from "../components/HeroBanner";
import React, {Component} from "react";
import {usePrismicDocumentsByTag} from "@prismicio/react";


export default function Home () {

    const [page, {error}] = usePrismicDocumentsByTag("home");

    if (error) {
        console.log("error", error)
    }
        return (
            <main> {page && (
                <section className="section-bg">
                    <HeroBanner
                        id="hero"
                        url={page.results[0].data.url[0].text}
                        button={page.results[0].data.button[0].text}
                        heading={page.results[0].data.hero_title[0].text}
                        subheading={page.results[0].data.hero_para[0].text}
                    />
                    <WhoWeAre
                        props={page.results[0].data.props}
                        propsHeading={page.results[0].data.choose_us_heading[0].text}
                        propsParagraph={page.results[0].data.choose_us_paragraph[0].text}
                    />
                    <AboutSection
                        aboutProps={page.results[0].data.about_props}
                        aboutHeading={page.results[0].data.about_heading[0].text}
                        aboutParagraph={page.results[0].data.about_paragraph[0].text}
                        aboutImage={page.results[0].data.about_image.url}
                    />
                    <Statistics
                        statistics={page.results[0].data.statistics}
                    />
                    <Services
                        services={page.results[0].data.services}
                        servicesHeading={page.results[0].data.heading[0].text}
                        servicesSubheading={page.results[0].data.subheading[0].text}
                    />
                </section>
                )}
            </main>
        )
}