import React from 'react';

export default function AboutSection(props) {
    return <section id="about" class="about">
        <div class="container">

            <div className="row ">
                <div className="col-lg-6">
                    <img src={props.aboutImage} className="img-fluid" alt="Parents holding a little child"/>
                </div>
                <div className="col-lg-6 pt-2 pt-lg-0">
                    <h3>{props.aboutHeading}</h3>
                    <p>{props.aboutParagraph}</p>
                    <ul>
                        {props.aboutProps.map((prop, index) => (
                        <li>
                            <i className="bx bx-check-double"></i>
                            {prop['para'][0].text}</li>
                        ))}
                    </ul>

                </div>
            </div>

        </div>
    </section>
}