import {usePrismicDocumentsByTag} from "@prismicio/react";
import React from "react";
import AboutSection from "../components/AboutSection";
import HeroBanner from "../components/HeroBanner";
import Paragraph  from "../components/Paragraph";
import Team from "../components/Team";
import Certificates from "../components/Certificates";

export default function About () {

    const [page, {error}] = usePrismicDocumentsByTag("about");
    console.log(page)
    if (error) {
        console.log("error", error)
    } else {

    }
    return (
            <main> {page && (
                <section>
                    <HeroBanner
                        id="about-hero"
                        heading={page.results[0].data.main_heading[0].text}
                        subheading={page.results[0].data.subheading[0].text}
                    />

                    <Paragraph longPara={page.results[0].data.description[0].text} />
                    <AboutSection
                        aboutProps={page.results[0].data.about_props}
                        aboutHeading={page.results[0].data.about_heading[0].text}
                        aboutParagraph={page.results[0].data.about_paragraph[0].text}
                        aboutImage={page.results[0].data.about_image.url}
                    />
                    <Team
                        teamHeading={page.results[0].data.team_heading[0].text}
                        uasTeam={page.results[0].data.team_subheading[0].text}
                        uasMembers={page.results[0].data.uas_members}
                        boardTeam={page.results[0].data.board_team[0].text}
                        boardMembers={page.results[0].data.board_members}
                    />
                    <Certificates />
                </section>
                )}
            </main>
        )
}