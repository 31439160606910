import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function Navigation() {
    // const handleSelect = (eventKey) => alert(`selected ${eventKey}`);

    return (
        <Navbar className="px-4 position-fixed" bg="light" expand="lg" id="header">
            <Container>
                <Navbar.Brand className="navbar logo me-auto" href="/"><h1 className="logo-text">UAS EASTERN EUROPEAN ADOPTIONS</h1></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto justify-content-center" >
                        <Nav.Item className='px-2'>
                            <Nav.Link eventKey="1" href="/">
                                Home
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='px-2'>
                            <Nav.Link eventKey="2" href="/about">
                                About UAS
                            </Nav.Link>
                        </Nav.Item>
                        <NavDropdown href="/programs" className='px-2' title="Programs" id="nav-dropdown">
                            <NavDropdown.Item href="/bulgaria-program" eventKey="4.1">Adopting a child from Bulgaria</NavDropdown.Item>
                            <NavDropdown.Item href="/haiti-program" eventKey="4.2">Adopting a child from Haiti</NavDropdown.Item>
                            <NavDropdown.Item href="/india-program" eventKey="4.3">Adopting a child from India</NavDropdown.Item>
                            <NavDropdown.Item href="/kazakhstan-program" eventKey="4.4">Adopting a child from Kazakhstan</NavDropdown.Item>
                            <NavDropdown.Item href="/ukraine-program" eventKey="4.5">Adopting a child from Ukraine</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Item className='px-2'>
                            <Nav.Link eventKey="2" href="/ways-to-give">
                                Ways to give
                            </Nav.Link>
                        </Nav.Item>
                        {/*<Nav.Item  className='px-2'>*/}
                        {/*    <Nav.Link eventKey="2" href="/about">*/}
                        {/*        News*/}
                        {/*    </Nav.Link>*/}
                        {/*</Nav.Item>*/}
                        <Nav.Item  className='px-2'>
                            <Nav.Link eventKey="2" href="/news">
                                News & Events
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item  className='px-2'>
                            <Nav.Link eventKey="2" href="/faq">
                                FAQs
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='px-2'>
                            <Nav.Link eventKey="2" href="/contact">
                                Contact
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
                <div className="d-flex align-items-center" id="header-social-links">
                    <a href="tel:204-947-2953" target="_blank" rel="noopener noreferrer" className="email"><span className="bi bi-telephone-outbound-fill"></span></a>
                    <a href="/contact" className="email"><span className="bi bi-envelope"></span></a>
                    <a target="_blank" href="https://www.facebook.com/UASadoptions/" className="facebook"><span className="bi bi-facebook"></span></a>
                </div>

            </Container>
        </Navbar>
    );
}


export default Navigation;