import React from 'react';

const Certificates = (props) => {
    return <section id="team" className="team pt-0">
        <div className="container">

            <div className="section-title">
                <h2>Our Certificates</h2>
                <p>We have received 2 appreciation certificates from Members of the Canadian Parliament</p>
            </div>

            <div className="row align-content-stretch">
                <div className="col-lg-6 col-md-4 d-flex align-items-stretch">
                    <div className="member">
                        <img src="./cert1.jpg" alt="" />
                        <h4>Kevin Lamoureux, MP</h4>
                        <span>Parliament of Canada</span>
                    </div>
                </div>
                <div className="col-lg-6 col-md-4 d-flex align-items-stretch">
                    <div className="member">
                        <img src="./cert2.png" alt="" />
                        <h4>Certificate of Appreciation</h4>
                        <span>House of Commons</span>
                    </div>
                </div>

            </div>
        </div>
    </section>
}

export default Certificates;

