import React from 'react';

export default function Statistics(props) {
    return <section id="counts" className="counts">
        <div className="container pt-5">

            <div className="row pt-4">
                {props.statistics.map((prop, index) => (
                <div className="col-lg-3 col-6 pb-4">
                    <div className="count-box">
                        <i className={prop.icon[0].text}></i>
                        <span className="purecounter">{prop.number}</span>
                        <p>{prop.heading1[0].text}</p>
                    </div>
                </div>
                ))}

            </div>

        </div>
    </section>
}