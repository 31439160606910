
import React from 'react'
import {usePrismicDocumentsByTag} from '@prismicio/react';

export default function Programs()  {
    const [programs] = usePrismicDocumentsByTag("programs");

    return (
            <main>
                {programs && (
                <section id="portfolio" className="portfolio">
                    <div className="container mt-5">

                        <div className="section-title">
                            <h2>{programs.results[0].data.heading[0].text}</h2>
                        </div>
                        <div className="row portfolio-container">
                            {programs.results[0].data.programs.map((program, index) => (
                            <div className="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp">
                                <div className="portfolio-wrap">
                                    <figure>
                                        <a href={program.path[0].text}>
                                            <img src={program.image.url} className="img-fluid" alt="" />
                                        </a>
                                    < /figure>

                                    <div className="portfolio-info">
                                        <h4><a href={program.path[0].text}>{program['country'][0].text}</a></h4>
                                        <p>{program.status[0].text}</p>
                                    </div>
                                </div>

                            </div>
                            ))}
                        </div>
                    </div>
                 </section>
                    )}
            </main>
        )
}