
import React from 'react';

const Services = (props) => {
    return <section id="services" className="services section-bg">
        <div className="container">

            <div className="section-title">
                <h2>{props.servicesHeading}</h2>
                <p>{props.servicesSubheading}</p>
            </div>

            <div className="row">
                {props.services.map((service, index) => (
                <div className="col-md-12 mt-4" >
                    <div className="icon-box">
                        {service.icon[0] &&
                            <i className={service.icon[0].text}></i>
                        }
                        <h4>{service['question'][0].text}</h4>
                        <p dangerouslySetInnerHTML={{__html: service['answer'][0].text}}></p>
                    </div>
                </div>
                ))}
            </div>

        </div>
    </section>
}

export default Services;