import React from 'react';

const Paragraph = (props) => {
    return <section  className="text-para d-flex flex-column justify-content-center align-items-center">
        <div className="container text-center text-md-left" data-aos="fade-up">
            <p dangerouslySetInnerHTML={{__html: props.longPara}}></p>
        </div>
    </section>
};
export default Paragraph;


