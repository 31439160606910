export default function Footer() {
    return <footer id="footer">

        <div className="footer-top">
            <div className="container">
                <div className="row">

                    <div className="col-lg-4 col-md-6 footer-contact">
                        <h3>UAS Eastern European Adoptions Inc.</h3>
                        <p>
                            777 Pritchard Avenue,
                            Winnipeg, MB R2X 0E8</p>
                        <p>
                            <strong>Phone:</strong> (204) 947-2953  </p>
                           <p> <strong>Email:</strong> info@uaseeadoption.ca</p>

                    </div>

                    <div className="col-lg-2 col-md-6 footer-links">
                        <h4>Read More</h4>
                        <ul>
                            <li><i className="bx bx-chevron-right"></i> <a href="/about">About UAS</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="/faq">FAQs</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="/news">UAS News</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="/links">Links</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-6 footer-links">
                        <h4>Harvesting Hope</h4>
                        <ul>
                            <li><i className="bx bx-chevron-right"></i> <a href="/ways-to-give">Ways to give</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="/harvesting-hope">Harvesting Hope</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="/volunteers">Volunteers</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="/sponsors">Sponsors</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="/contact">Contact Us</a></li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-6 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.gov.mb.ca/fs/childfam/adoption.html">Government of Manitoba , Department of Families</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://mpue.ca/">Manitoba Parents for Ukrainian Education</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.permanency.ca/">Adoption Council of Canada</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.gov.mb.ca/fs/childfam/adoption.html">Information on Adoption</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.adoptivefamilies.com/">Adoptive Families Magazine</a></li>

                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </footer>
}