
import Services from "../components/Services";
import HeroBanner from "../components/HeroBanner";
import {usePrismicDocumentsByTag} from '@prismicio/react';

export default function Faq()  {

    const [faq, {error}] = usePrismicDocumentsByTag("faq");
    if (error) {
        console.log(error)
    }

    return (
        <main>
            {faq && (
                <div className="program-container">
                    <HeroBanner
                        id="faq"
                        heading={faq.results[0].data.hero_title[0].text}>
                    </HeroBanner>
                    <Services
                        services={faq.results[0].data.faq}
                        servicesHeading={faq.results[0].data.heading[0].text}
                        servicesSubheading=""
                    >
                    </Services>

                </div>
            )}
        </main>
    )

}