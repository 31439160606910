import React, {Component} from 'react'

export default class Contact extends Component {



    handleSubmit() {
        setTimeout(
            () => {
                alert("The form was successfully submitted. We will get back to you as soon as possible. Thank you!");
                document.getElementById("contact-form").reset()

            }, 1000
         );

    }



    render() {
        return (
            <main>
            <section id="contact" className="contact section-bg">
                <div className="container mt-5">

                    <div className="section-title">
                        <h2>Contact</h2>
                        <p>You can contact us by email, phone, or complete the following form to request an information package.</p>
                    </div>

                    <div className="row mt-5 justify-content-center">

                        <div className="col-lg-10">

                            <div className="info-wrap">
                                <div className="row">
                                    <div className="col-lg-4 info">
                                        <i className="bi bi-geo-alt"></i>
                                        <h4>Mail:</h4>
                                        <p>UAS Eastern European Adoptions Inc.<br/>
                                            777 Pritchard Avenue<br />
                                            Winnipeg, MB R2X 0E8</p>
                                    </div>

                                    <div className="col-lg-4 info mt-4 mt-lg-0">
                                        <i className="bi bi-envelope"></i>
                                        <h4>Email:</h4>
                                        <p>info@uaseeadoption.ca</p>
                                    </div>

                                    <div className="col-lg-4 info mt-4 mt-lg-0">
                                        <i className="bi bi-phone"></i>
                                        <h4>Call:</h4>
                                        <p>(204) 947-2953</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="container">
                        <div className="row pt-5 mx-0 justify-content-center">
                            <div >
                                <iframe name="dummyframe" id="dummyframe" className="frame-contact-form"></iframe>

                                <form
                                    method="POST"
                                    className="w-100 rounded-1 p-4 border bg-white contact-form"
                                    action="https://forms.reform.app/headless/uNgsJJ/untitled-form/8cknea/submissions"
                                    target="dummyframe"
                                    id="contact-form"
                                    onSubmit={this.handleSubmit}

                                >
                                    <label className="d-block mb-4">
                                        <span className="form-label d-block">Your name</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required
                                            minLength="1"
                                            maxLength="50"
                                            id="827d6685-45f3-4bde-a6cb-e2817868c1e7"
                                            name="answers[827d6685-45f3-4bde-a6cb-e2817868c1e7]"
                                        />
                                    </label>

                                    <label className="d-block mb-4">
                                        <span className="form-label d-block">Email address</span>
                                        <input
                                            type="email"
                                            id="1025b37a-cba9-4e7d-b801-59780a730b75"
                                            name="answers[1025b37a-cba9-4e7d-b801-59780a730b75]"
                                            className="form-control"
                                            minLength="4"
                                            maxLength="30"
                                            required
                                        />
                                    </label>

                                    <label className="d-block mb-4">
                                        <span className="form-label d-block">Province</span>
                                        <input
                                            required
                                            type="text"
                                            id="c593ec50-b7d6-41f1-ae12-0118913765b7"
                                            name="answers[c593ec50-b7d6-41f1-ae12-0118913765b7]"
                                            className="form-control"
                                            minLength="2"
                                            maxLength="20"
                                        />
                                    </label>

                                    <label className="d-block mb-4">
                                        <span className="form-label d-block">Message</span>
                                        <textarea
                                            id="409c031c-a40e-49c8-bed6-20143922853e"
                                            name="answers[409c031c-a40e-49c8-bed6-20143922853e]"
                                            className="form-control"
                                            rows="3"
                                            placeholder="Ask us anything"
                                            maxLength="500"
                                        ></textarea>
                                    </label>

                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-primary px-3 rounded-3">
                                            Contact Us
                                        </button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            </main>
        )
    }
}