

import HeroBanner from "../../components/HeroBanner";
import Steps from "../../components/Steps";
import {usePrismicDocumentsByTag} from '@prismicio/react';

export default function BulgariaProgram()  {

    const [bulgaria] = usePrismicDocumentsByTag("bulgaria");

    return (
        <main>
            {bulgaria && (
                <div className="program-container">
                    <HeroBanner
                        id="bulgaria-program"
                        heading={bulgaria.results[0].data.hero_title[0].text}>
                    </HeroBanner>
                    <Steps
                        image={bulgaria.results[0].data['list-image'].url}
                        paragraph={bulgaria.results[0].data.paragraph[0].text}
                        heading={bulgaria.results[0].data.heading[0].text}
                        steps={bulgaria.results[0].data.step_list}
                    />

                    <section>
                        <div className="container">
                            <div className="section-title">
                                <h2>{bulgaria.results[0].data.families_number[0].text}</h2>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </main>
    )

}