

import HeroBanner from "../../components/HeroBanner";
import Steps from "../../components/Steps";
import {usePrismicDocumentsByTag} from '@prismicio/react';

export default function UkraineProgram()  {

    const [ukraine] = usePrismicDocumentsByTag("ukraine");

        return (
            <main>
                {ukraine && (
                    <div className="program-container">
                <HeroBanner
                    id="ukraine-program"
                    heading={ukraine.results[0].data.hero_title[0].text}>
                </HeroBanner>
                <Steps
                    image={ukraine.results[0].data['list-image'].url}
                    paragraph={ukraine.results[0].data.paragraph[0].text}
                    heading={ukraine.results[0].data.heading[0].text}
                    steps={ukraine.results[0].data.step_list}
                />

                <section>
                    <div className="container">
                        <div className="section-title">
                            <h2>{ukraine.results[0].data.families_number[0].text}</h2>
                        </div>
                    </div>
                </section>
                    </div>
                    )}
            </main>
        )

}