import Nav from "./components/layout/Navbar/Nav";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import WaysToGive from "./pages/WaysToGive";
import UkraineProgram from "./pages/Programs/UkraineProgram"
import HaitiProgram from "./pages/Programs/HaitiProgram"
import Footer from "./components/layout/Footer/Footer";
import Programs from "./pages/Program";
import BulgariaProgram from "./pages/Programs/BulgariaProgram";
import KazakhstanProgram from "./pages/Programs/KazakhstanProgram";
import IndiaProgram from "./pages/Programs/IndiaProgram";
import Faq from "./pages/Faq";
import HarvestingHope from "./pages/HarvestingHope";
import Volunteers from "./pages/Volunteers";
import Sponsors from "./pages/Sponsors";
import Links from "./pages/Links";
import News from "./pages/News";
import Article from "./pages/Article";

function App() {
  let component
  switch (window.location.pathname) {
    case "/":
      component = <Home />
      break
    case "/about":
      component = <About />
      break
    case "/article":
      component = <Article />
      break
    case "/contact":
      component = <Contact />
      break
    case "/faq":
      component = <Faq />
      break
    case "/volunteers":
      component = <Volunteers/>
      break
    case "/sponsors":
      component = <Sponsors/>
      break
    case "/links":
      component = <Links/>
      break
    case "/news":
      component = <News/>
      break
    case "/harvesting-hope":
      component = <HarvestingHope />
      break
    case "/ways-to-give":
      component = <WaysToGive />
      break
    case "/programs":
      component = <Programs />
      break
    case "/ukraine-program":
      component = <UkraineProgram />
      break
    case "/haiti-program":
      component = <HaitiProgram />
      break
    case "/bulgaria-program":
      component = <BulgariaProgram />
      break
    case "/kazakhstan-program":
      component = <KazakhstanProgram />
      break
    case "/india-program":
      component = <IndiaProgram />
      break
  }
  return (
    <>
      <Nav />
      <div id="main-content">
        {component}
      </div>
      <Footer />
    </>
  )
}

export default App;
