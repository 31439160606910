
import React from 'react'
import {usePrismicDocumentsByTag} from '@prismicio/react';
import {
    MDBCarousel,
    MDBCarouselItem,
} from 'mdb-react-ui-kit';

export default function Article()  {
    const [news] = usePrismicDocumentsByTag("news");
    console.log(news)
    console.log("location", window.location.search.split('id='))
    let id = window.location.search.split('id=')[1];
    let article;
    if (news) {
       article = news.results[0].data.news_list[id]
        console.log('articel', article)
    }


    return (
        <main>
            {news && (
                <section>
                    <div className="container breadcrumbs pt-0">

                        <div className="d-flex justify-content-between align-items-center">
                            <h2>{article.heading[0].text}</h2>
                            <ol>
                                <li><a href="/news">All news</a></li>
                                <li>{article.heading[0].text}</li>
                            </ol>
                        </div>

                    </div>

                    <div id="portfolio-details" className="portfolio-details">
                        <div className="container">

                        <div className="row gy-4">

                          <div className="col-lg-8">
                              <MDBCarousel showControls showIndicators>
                                  <MDBCarouselItem
                                      className='w-100 d-block'
                                      itemId={1}
                                      src={article.image.url}
                                      alt='...'
                                  />
                                  {article.image2.url &&
                                      <MDBCarouselItem
                                          className='w-100 d-block'
                                          itemId={2}
                                          src={article.image2.url}
                                          alt='...'
                                      />
                                  }
                                  {article.image3.url &&
                                      <MDBCarouselItem
                                          className='w-100 d-block'
                                          itemId={3}
                                          src={article.image3.url}
                                          alt='...'
                                      />
                                  }
                              </MDBCarousel>
                          </div>

                          <div className="col-lg-4">
                            <div className="portfolio-info">
                              <h3>Details</h3>
                              <ul>
                                <li><strong>Date</strong>: {article.date[0].text}</li>
                                  { article.time[0] &&
                                      <li><strong>Time</strong>: {article.time[0].text}</li>
                                  }
                                  {article.location[0] &&
                                      <li><strong>Location</strong>: <span
                                          dangerouslySetInnerHTML={{__html: article.location[0].text}}></span></li>
                                  }
                                  {article.link.url &&
                                      <li><strong>Website</strong>: <a href={article.link.url}><span> {article.link.url}</span></a></li>
                                  }

                              </ul>
                            </div>
                            <div className="portfolio-description">
                              <h2>{article.heading[0].text}</h2>
                              <p dangerouslySetInnerHTML={{__html: article.paragraph[0].text}}></p>
                            </div>
                          </div>

                        </div>
                        </div>

                    </div>
                </section>
            )}
        </main>
    )
}