import React from 'react';

const Team = (props) => {
    return <section id="team" className="team pt-0">
        <div className="container">

            <div className="section-title">
                <h2>{props.teamHeading}</h2>
                <p>{props.uasTeam}</p>

            </div>

            <div className="row align-content-stretch">
                {props.uasMembers.map((member, index) => (
                <div className="col-lg-3 col-md-4 d-flex align-items-stretch">
                    <div className="member">
                        <h4>{member.name[0].text}</h4>
                        <span>{member.lastname[0].text}</span>
                    </div>
                </div>
                ))}

            </div>
            <div className="section-title pt-3">
                <p>{props.boardTeam}</p>
            </div>
            <div className="row justify-content-between">
                {props.boardMembers.map((member, index) => (
                    <div className="col-lg-2 col-md-4 d-flex align-items-stretch">
                        <div className="member">
                            <h4>{member.name[0].text}</h4>
                            <span>{member.lastname[0].text}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
}

export default Team;

