import React from 'react'
import {usePrismicDocumentsByTag} from '@prismicio/react';
import HeroBanner from "../components/HeroBanner";
import Services from "../components/Services";

export default function HarvestingHope() {
    const [page] = usePrismicDocumentsByTag("harvesting-hope");

    return (
        <main>
            {page && (
                <section className="section-bg">
                    <HeroBanner
                        id="hope"
                        heading={page.results[0].data.hero_title[0].text}
                        subheading={page.results[0].data.hero_para[0].text}
                    />
                    <Services
                        services={page.results[0].data.ways}
                        servicesHeading={page.results[0].data.heading[0].text}
                        servicesSubheading={page.results[0].data.subheading[0].text}
                    />
                </section>
            )}
        </main>
    )
}