import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import "boxicons"

import { PrismicProvider } from '@prismicio/react'
import { client } from './prismic'

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <PrismicProvider client={client}>
    <App />
    </PrismicProvider>
);
