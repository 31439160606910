import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'uasadoption'

export const client = prismic.createClient(repositoryName, {
    // If your repository is private, add an access token
    accessToken: 'MC5ZejRweEJBQUFDY0FiUkxQ.S--_ve-_vXFZ77-977-9Xu-_ve-_ve-_vQfvv70wI0xff2_vv71yZgc-R--_ve-_vQPvv73vv73vv73vv70',

    // This defines how you will structure URL paths in your project.
    // Update the types to match the Custom Types in your project, and edit
    // the paths to match the routing in your project.
    //
    // If you are not using a router in your project, you can change this
    // to an empty array or remove the option entirely.
    routes: [

    ],
})