import React from 'react'
import {usePrismicDocumentsByTag} from '@prismicio/react';
import HeroBanner from "../components/HeroBanner";

export default function Sponsors() {
    const [page] = usePrismicDocumentsByTag("sponsors");

    return (
        <main>
            {page && (
                <section className="section-bg">
                    <HeroBanner
                        id="sponsors-hero"
                        heading={page.results[0].data.hero_title[0].text}
                    />
                    <div className="container pt-5">
                        <div className="row">

                            <div className="col-lg-12 pt-4 pt-lg-0">
                                <h4>{page.results[0].data.heading1[0].text}</h4>
                                <p>{page.results[0].data.para1[0].text}</p>
                                <p><strong>{page.results[0].data.para2[0].text}</strong></p>
                                <h4>{page.results[0].data.heading2[0].text}</h4>
                                <ul className="list-group pb-4">
                                    {page.results[0].data.list.map((item, index) => (
                                        <li><i className="bi-chevron-double-right icon-color"></i> {item.item[0].text}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {/*<div className="col-lg-6">*/}
                            {/*    <img src={page.results[0].data.hero_image.url} className="img-fluid" alt="Parents holding a little child"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </section>

            )}
        </main>
    )
}