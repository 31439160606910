
import React from 'react';

const Steps = (props) => {
    return <section id="steps" className="services section-bg">
        <div className="container pt-3">
        <div className="row">
            <div className="col-lg-6">
                <img src={props.image} className="img-fluid" alt="Parents holding a little child"/>
            </div>

            <div className="col-lg-6 pt-4 pt-lg-0">
                    <h3>{props.heading}</h3>
                    <p>
                        {props.paragraph}
                    </p>
                    <ul className="list-group">
                        {props.steps.map((step, index) => (
                        <li><i className={`bi bi-${index+1}-circle icon-color`}></i> {step.step[0].text}
                        </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>

    </section>
}

export default Steps;